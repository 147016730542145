import {
	AltPersistButton,
	Box,
	EditPage,
	HasOne,
	Repeater,
	LegacyDeprecatedEditorFormerlyKnownAsRichTextField,
	TextField,
	VariableScalar,
	VideoUploadField,
} from '@contember/admin'
import * as React from 'react'
import { LocaleSideDimension } from '../components'
import { ImageField, MediumField } from '../fields'

export const PrivatePage = (
	<EditPage
		pageName="privatePage"
		entity="PrivatePage(unique = One)"
		rendererProps={{ title: 'Private Page', persistButtonComponent: AltPersistButton }}
	>
		<LocaleSideDimension>
			<TextField field="link.url" label="URL" defaultValue={new VariableScalar('slugPrefix')} />
		</LocaleSideDimension>

		<Box heading="Cover">
			<MediumField field="medium" label="Medium" />
			<LocaleSideDimension>
				<TextField field="title" label="Title" />
				<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="text" label="Text" />
			</LocaleSideDimension>
		</Box>

		<Box heading="Private Option">
			<HasOne field="privateOptions">
				<Repeater field="items" label="Private Options" sortableBy="order">
					<ImageField field="image" label="Image" />
					<LocaleSideDimension>
						<TextField field="title" label="title" />
						<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="text" label="Text" />
						<TextField field="button" label="Send Button" />
						<TextField field="url" label="url" />
					</LocaleSideDimension>
				</Repeater>
			</HasOne>
		</Box>

		<Box heading="Thank You">
			<HasOne field="thankYou">
				<LocaleSideDimension>
					<TextField field="title" label="Title" />
					<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="text" label="Text" />
					<TextField field="contactButton" label="Button" />
					<TextField field="url" label="url" />
				</LocaleSideDimension>
				<ImageField field="sponsors" label="Sponsors" />
			</HasOne>
		</Box>

		<Box heading="Video Section">
			<HasOne field="videos">
				<Repeater field="items" label="Items" sortableBy="order">
					<VideoUploadField field="video.url" label="Video" />
					<ImageField field="video.poster" label="Poster Image" />
				</Repeater>
			</HasOne>
		</Box>
	</EditPage>
)
