import { MultiEditPage, SelectField, TextField, AltPersistButton } from '@contember/admin'
import * as React from 'react'

export const RedirectsPage = (
	<MultiEditPage
		pageName="redirects"
		entities="Redirect"
		rendererProps={{
			title: 'Redirects',
			enableAddingNew: true,
			enableRemoving: true,
			persistButtonComponent: AltPersistButton,
		}}
	>
		<TextField field="link.url" label="From URL" defaultValue="/" />
		<SelectField field="target" label="To URL" options="Linkable.url" />
	</MultiEditPage>
)
