import {
	Button,
	CheckboxField,
	Component,
	CreatePage,
	DateField,
	DateFieldView,
	EditPage,
	Field,
	HasMany,
	MultiEditPage,
	NumberField,
	PageLinkButton,
	PageLinkById,
	Repeater,
	RichTextLeaf,
	SelectField,
	SideDimensions,
	TableCell,
	TablePage,
	TextField,
	useEntity,
	useEntityList,
	useField,
	VariableScalar,
	LegacyDeprecatedEditorFormerlyKnownAsRichTextField,
	GenericPage,
	DataBindingProvider,
	TitleBar,
	DataGrid,
	FeedbackRenderer,
	DateCell,
	TextCell,
	HasManyAbsentCell,
	EntitySubTree,
	GenericCell,
	HasOne,
	Entity,
	BooleanCell,
	TextAreaField,
	EntityListAccessor,
	Box,
	useEnvironment,
} from '@contember/admin'
import * as React from 'react'
import { EditButton, LocaleSideDimension } from '../components'
import { Conditional } from '../components/Conditional'
import { ImageField, LinkUrlField } from '../fields'
import { PriceField } from '../fields/PriceField'
import { fullEditorInlineButtons } from '../utils'

const LinkToWeb = React.memo<{ path: string, children: React.ReactNode }>(({ path, children }) => {
	const env = useEnvironment()
	return <a href={`${env.getValue('FRONTEND_URL')}/${path}`} style={{textDecoration: 'underline'}}>{children}</a>
})

const LinkToFakturoidInvoice = React.memo<{ invoiceId: string | null, children: React.ReactNode }>(({ invoiceId, children }) => {
	if (!invoiceId) {
		return <>{children}</>
	}
	return <a href={`https://app.fakturoid.cz/fuegocartel/invoices/${invoiceId}`} style={{textDecoration: 'underline'}}>{children}</a>
})

const CourseForm = Component(
	() => (
		<>
			<LocaleSideDimension>
				<TextField field="title" label="Název" size="large" />
				<LinkUrlField label="URL" derivedFrom="title" />
				<TextField field="chefName" label="Jméno Kuchaře" />
				<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="description" label="Popis" />
				<TextField field="menuTitle" label="Menu titulek" />
				<LegacyDeprecatedEditorFormerlyKnownAsRichTextField inlineButtons={fullEditorInlineButtons} field="menuText" label="Menu" />
			</LocaleSideDimension>

			<ImageField field="image" label="Obrázek" />

			<CheckboxField field="available" label="Dostupné na webu" defaultValue={true} />
			<NumberField field="capacity" label="Výchozí kapacita" />
			<PriceField field="price" label="Cena" />
		</>
	),
	'CourseForm',
)

export const EditCourse = (
	<EditPage pageName="editCourse" entity="Course(id = $id)">
		<CourseForm />
	</EditPage>
)

export const CreateCourse = (
	<CreatePage pageName="createCourse" entity="Course">
		<CourseForm />
	</CreatePage>
)

export const Courses = (
	<MultiEditPage
		pageName="courses"
		entities="Course"
		orderBy="order"
		rendererProps={{ enableAddingNew: false, enableRemoving: false, sortableBy: 'order', title: "Kurzy", beforeContent: <PageLinkButton to="createCourse">Nový kurz</PageLinkButton> }}
	>
		<LocaleSideDimension>
			<Field field="title" />
		</LocaleSideDimension>

		<EditButton pageName="editCourse">Změnit</EditButton>
		<EditButton pageName="addDates">Přidat termíny</EditButton>
	</MultiEditPage>
)

const AddDatesInner = Component(
	() => {
		const [dates, setDates] = React.useState<string[]>([])
		const [selected, setSelected] = React.useState(false)
		const toggleDate = React.useCallback(
			(date: string) => {
				setDates(dates => {
					if (dates.includes(date)) {
						return dates.filter(other => other !== date)
					} else {
						return [...dates, date]
					}
				})
			},
			[setDates],
		)
		const defaultCapacity = useField('capacity').value
		const datesEntities = useEntityList({ field: 'dates', orderBy: 'start' })

		const createEntities = React.useCallback(() => {
			datesEntities.batchUpdates(get => {
				for (const start of dates) {
					get().createNewEntity(get => {
						get().updateValues({
							capacity: defaultCapacity,
							start,
						})
					})
				}
			})
			setSelected(true)
		}, [defaultCapacity, datesEntities, dates])

		const startMonth = new Date().getMonth()
		if (!selected) {
			const monthElements = []

			for (let month = startMonth; month < startMonth + 12; month++) {
				const dayElements = []
				const firstDay = new Date()
				firstDay.setMonth(month)
				firstDay.setDate(1)
				const day = new Date(firstDay)
				while (day.getMonth() === firstDay.getMonth()) {
					const str = day.toISOString().substr(0, 10)
					const selected = dates.includes(str)
					dayElements.push(
						<div
							title={str}
							style={{
								fontWeight: selected ? 'bold' : 'normal',
								gridColumn: ((day.getDay() + 6) % 7) + 1,
								gridRow: Math.floor((((firstDay.getDay() + 6) % 7) + day.getDate() - 1) / 7) + 2,
								background: selected ? 'rgba(0,0,120,.5)' : 'none',
							}}
							onClick={e => toggleDate(str)}
						>
							{day.getDate()}
						</div>,
					)
					day.setDate(day.getDate() + 1)
				}

				monthElements.push(
					<div
						style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', textAlign: 'center', maxWidth: '25em' }}
					>
						<div style={{ gridColumn: 'span 7', textTransform: 'uppercase' }}>
							{new Intl.DateTimeFormat('cs', { month: 'long', year: 'numeric' }).format(firstDay)}
						</div>
						{dayElements}
					</div>,
				)
			}

			return (
				<div>
					<h2>
						Přidat termínu kurzu <Field field="locales(locale.code = 'cs').title" />
					</h2>
					<div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gridGap: '2em' }}>{monthElements}</div>
					<div style={{textAlign: 'center'}}>
						<button style={{margin: '1em auto', fontSize: '1.5rem', padding: '.5em'}} onClick={e => createEntities()}>Pokračovat</button>
					</div>
					
				</div>
			)
		} else {
			return (
				<>
					<h2>
						<Field field="locales(locale.code = 'cs').title" />
					</h2>
					<Repeater field="dates" initialEntityCount={0} label="Termíny" orderBy="start">
						<DateField field="start" label="Datum" />
						<NumberField field="capacity" label="Kapacita" />
					</Repeater>
				</>
			)
		}
	},
	() => (
		<>
			<Field field="locales(locale.code = 'cs').title" />
			<Field field="capacity" />
			<Repeater field="dates" initialEntityCount={0} label="Termíny" orderBy="start">
				<Field field="capacity" />
				<Field field="start" />
			</Repeater>
		</>
	),
	'AddDatesInner',
)

export const AddDates = (
	<EditPage pageName="addDates" entity="Course(id = $id)">
		<AddDatesInner />
	</EditPage>
)

const VoucherForm = Component(
	() => {
		const entity = useEntity()

		if (entity.existsOnServer) {
			return (
				<>
					<Field<boolean> field="available" format={available => (available ? '✔ Dostupné' : '❌ Nedostupné')} />
					<CheckboxField field="available" label="Dostupnost" />
					Sleva: <Field<number> field="discount" format={discount => discount && discount / 100} />
					<br />
					Expirace: <DateFieldView field="expiresAt" />
					<br />
					Sezóna: <Field<string> field="season.name" />
					<LocaleSideDimension>
						<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="description" label="Popis" />
					</LocaleSideDimension>
					<EditButton pageName="voucherCodesByVoucher">Vydané kódy</EditButton>
				</>
			)
		} else {
			return (
				<>
					<PriceField field="discount" label="Sleva" />
					<CheckboxField field="available" label="Dostupné" defaultValue={true} />
					<DateField field="expiresAt" label="Expirace" />
					<SelectField field="season" label="Sezóna" options="VoucherIssuranceLimitSeason.name" />
					<LocaleSideDimension>
						<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="description" label="Popis" />
					</LocaleSideDimension>
					<EditButton pageName="voucherCodesByVoucher">Vydané kódy</EditButton>
				</>
			)
		}
	},
	() => {
		return (
			<>
				<PriceField field="discount" label="Sleva" />
				<CheckboxField field="available" label="Dostupné" defaultValue={true} />
				<DateField field="expiresAt" label="Expirace" />
				<SelectField field="season" label="Sezóna" options="VoucherIssuranceLimitSeason.name" />
				<Field<string> field="season.name" />
				<LocaleSideDimension>
					<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="description" label="Popis" />
				</LocaleSideDimension>
			</>
		)
	},
	'VoucherForm',
)

export const Vouchers = (
	<MultiEditPage
		pageName="vouchers"
		entities="Voucher"
		rendererProps={{
			enableRemoving: false,
			addButtonText: 'Nový voucher',
			title: "Vouchery",
		}}
		orderBy="available desc, discount asc"
	>
		<VoucherForm />
	</MultiEditPage>
)

const VoucherCodesCells = <>
	<DateCell
		field="orderItem.order.createdAt"
		header="Vytvoření objednávky"
		locale="cs-CZ"
		format={{dateStyle: "medium", timeStyle: "short"} as any}
		shrunk
		hidden
	/>
	<DateCell
		field="redemption.createdAt"
		header="Využito"
		locale="cs-CZ"
		format={{dateStyle: "medium", timeStyle: "short"} as any}
		shrunk
	/>
	<TextCell field="orderItem.order.email" header="Email" />
	<TextCell field="orderItem.order.fakturoidId" header="Faktura" format={id => <LinkToFakturoidInvoice invoiceId={id as string}>{id as string}</LinkToFakturoidInvoice>} />
	<TextCell field="redemption.fakturoidId" header="Faktura při využití" format={id => <LinkToFakturoidInvoice invoiceId={id as string}>{id as string}</LinkToFakturoidInvoice>} />
	
	<TextCell field="code" header="Kód" />
	<DateCell
		field="voucher.expiresAt"
		header="Expirace"
		locale="cs-CZ"
		format={{dateStyle: "medium", timeStyle: "short"} as any}
		shrunk
	/>
	<TextCell field="voucher.season.name" header="Sezóna" />
	<TextCell field="orderItem.order.email" header="Email objednavatele" hidden />
	<TextCell field="orderItem.order.phoneNumber" header="Telefon objednavatele" hidden />
	<GenericCell shrunk canBeHidden={false}>
		<HasOne field="orderItem.order"><EditButton pageName="orderDetail">Detail objednávky</EditButton></HasOne>
	</GenericCell>
	<GenericCell shrunk canBeHidden={false}>
		<HasOne field="redemption"><EditButton pageName="orderDetail">Detail využití</EditButton></HasOne>
	</GenericCell>
</>


export const VoucherCodesByVoucher = (
	<GenericPage pageName="voucherCodesByVoucher">
		<DataBindingProvider stateComponent={FeedbackRenderer}>
			<EntitySubTree entity="Voucher(id = $id)">
				<TitleBar>
					Vouchery za <Field<number> field="discount" format={discount => discount! / 100} /> v sezóně <Field field="season.name" />
				</TitleBar>
				<DataGrid entities="VoucherCode[voucher.id = $id]" itemsPerPage={50}>
					{VoucherCodesCells}
				</DataGrid>
			</EntitySubTree>
		</DataBindingProvider>
	</GenericPage>
)

export const VoucherCodes = (
	<GenericPage pageName="voucherCodes">
		<DataBindingProvider stateComponent={FeedbackRenderer}>
			<TitleBar>
				Vydané vouchery
			</TitleBar>
			<PageLinkButton to="createVoucherCode">Nový kód</PageLinkButton>
			<DataGrid entities="VoucherCode" itemsPerPage={50}>
				{VoucherCodesCells}
			</DataGrid>
		</DataBindingProvider>
	</GenericPage>
)


export const VoucherSeasons = (
	<MultiEditPage
		pageName="voucherSeasons"
		entities="VoucherIssuranceLimitSeason"
		rendererProps={{
			enableRemoving: false,
			addButtonText: 'Přidat sezónu',
			title: 'Sezóny',
		}}
	>
		<TextField field="name" label="Název" />
		<PriceField field="limit" label="Limit" />
	</MultiEditPage>
)



export const OrdersProcessing = (
	<GenericPage pageName="orderNotprocessedList">
		<DataBindingProvider stateComponent={FeedbackRenderer}>
			<TitleBar>
				Nedokončené objednávky
			</TitleBar>
			<DataGrid entities="Order[confirmationSent = null][canceledAt = null]" itemsPerPage={50}>
				<DateCell
					field="createdAt"
					header="Vytvoření"
					initialOrder="desc"
					locale="cs-CZ"
					format={{dateStyle: "medium", timeStyle: "short"} as any} 
					shrunk
				/>
				<TextCell field="email" header="Email" />
				<TextCell field="locale.label" header="Jazyk" />
				<TextCell field="totalPrice" header="Cena" format={price => (price as number / 100).toString(10)} />
				<TextCell field="fakturoidId" header="Faktura" format={id => id && <LinkToFakturoidInvoice invoiceId={id as string}>{id as string}</LinkToFakturoidInvoice>} />

				
				<DateCell
					field="confirmationSent"
					header="Posláno potvrzení"
					locale="cs-CZ"
					format={{dateStyle: "medium", timeStyle: "short"} as any} 
					shrunk
					hidden
				/>
				<TextCell field="secretCode" header="Tajný kód" hidden />
				
				{/* <HasManyAbsentCell field="orderItem(voucherCode.id=null)" header="Voucher" render={({accessor}) => <>{accessor.length === 0 ? 'Ne' : 'Ano'}</>}>
					<React.Fragment />
				</HasManyAbsentCell> */}
				
				<GenericCell shrunk canBeHidden={false}>
					<EditButton pageName="orderDetail">Detail</EditButton>
				</GenericCell>
			</DataGrid>
		</DataBindingProvider>

	</GenericPage>
)

export const Orders = (
		<GenericPage pageName="orderList">
			<DataBindingProvider stateComponent={FeedbackRenderer}>
				<TitleBar>
					Zaplacené objednávky
				</TitleBar>
				<DataGrid entities="Order[confirmationSent != null]" itemsPerPage={50}>
					<DateCell
						field="createdAt"
						header="Datum vytvoření"
						initialOrder="desc"
						locale="cs-CZ"
						shrunk
					/>
					<TextCell field="email" header="Email" />
					<TextCell field="locale.label" header="Jazyk" />
					<TextCell field="totalPrice" header="Cena" format={price => (price as number / 100).toString(10)} />
					<TextCell field="fakturoidId" header="Faktura" format={id => <LinkToFakturoidInvoice invoiceId={id as string}>{id}</LinkToFakturoidInvoice>} />

					
					<DateCell
						field="confirmationSent"
						header="Posláno potvrzení"
						locale="cs-CZ"
						format={{dateStyle: "medium", timeStyle: "short"} as any} 
						shrunk
						hidden
					/>
					<TextCell field="secretCode" header="Tajný kód" hidden />
					
					{/* <HasManyAbsentCell field="orderItem(voucherCode.id=null)" header="Voucher" render={({accessor}) => <>{accessor.length === 0 ? 'Ne' : 'Ano'}</>}>
						<React.Fragment />
					</HasManyAbsentCell> */}
					
					<GenericCell shrunk canBeHidden={false}>
						<EditButton pageName="orderDetail">Detail</EditButton>
					</GenericCell>
				</DataGrid>
			</DataBindingProvider>
	
		</GenericPage>
	)

export const Shipments = (
<GenericPage pageName="shipments">
	<DataBindingProvider stateComponent={FeedbackRenderer}>
		<TitleBar>
			K poslání poštou
		</TitleBar>
		<DataGrid entities="Shipment[orderItem.order.confirmationSent != null]" itemsPerPage={50}>
			<DateCell
				field="orderItem.order.createdAt"
				header="Datum objednávky"
				locale="cs-CZ"
				format={{dateStyle: "medium", timeStyle: "short"} as any} 
				shrunk
				hidden
			/>
			<DateCell
				field="orderItem.order.confirmationSent"
				header="Datum dokončení obj."
				initialOrder="desc"
				locale="cs-CZ"
				format={{dateStyle: "medium", timeStyle: "short"} as any} 
				shrunk
			/>
			<TextCell field="orderItem.order.email" header="Email" />
			<TextCell field="orderItem.order.locale.label" header="Jazyk objednávky" />
			<TextCell field="orderItem.order.fakturoidId" header="Faktura" format={id => <LinkToFakturoidInvoice invoiceId={id as string}>{id as string}</LinkToFakturoidInvoice>} hidden />


			<TextCell field="address.name" header="Adresa: Jméno" hidden />
			<TextCell field="address.firstLine" header="Adresa: První řádek" hidden />
			<TextCell field="address.secondLine" header="Adresa: Druhý řádek" hidden />
			<TextCell field="address.postCode" header="Adresa: PSČ" hidden />

			<GenericCell shrunk canBeHidden={false}>
				<HasOne field="orderItem.order">
					<EditButton pageName="orderDetail">Detail objednávky</EditButton>
				</HasOne>
			</GenericCell>
		</DataGrid>
	</DataBindingProvider>
</GenericPage>
)

const countParticipants = (list: EntityListAccessor): number => {
	return Array.from(list).reduce((s, i) => s + (i.getField<number>('peopleCount').value || 0), 0)
}

export const CourseDates = (
	<GenericPage pageName="courseDates">
		<DataBindingProvider stateComponent={FeedbackRenderer}>
			<TitleBar>
				Vypsané termíny
			</TitleBar>
			<DataGrid entities="CourseDate" itemsPerPage={50}>
				<DateCell
					field="start"
					header="Datum"
					initialOrder="asc"
					locale="cs-CZ"
					shrunk
				/>
				<TextCell field="course.locales(locale.code='cs').title" header="Kurz" />
				<HasManyAbsentCell field="participants[canceledAt=null]" header="Účastníci" render={({accessor}) => <>{countParticipants(accessor)} účastníků</>}>
					<Field field="peopleCount" />
				</HasManyAbsentCell>
				<TextCell field="capacity" header="Kapacita" justification="justifyEnd" />
				<HasManyAbsentCell field="waiters[unsubscribedAt=null]" header="Čekací listina" render={({accessor}) => <>{accessor.length === 0 ? 'Nikdo' : `${accessor.length}`}</>}>
					<React.Fragment />
				</HasManyAbsentCell>
				<GenericCell shrunk canBeHidden={false}>
					<EditButton pageName="courseDateDetail">Detail</EditButton>
				</GenericCell>
			</DataGrid>
		</DataBindingProvider>
	</GenericPage>
)

const ParticipationDetailButton = Component(
	() => {
		const order = useEntity('orderItem.order')

		if (order.existsOnServer) {
			return <HasOne field="orderItem.order"><EditButton pageName="orderDetail">Detail objednávky</EditButton></HasOne>
		}
		return <EditButton pageName="participationDetail">Detail skupiny</EditButton>
	},
	() => {
		return <Field field="orderItem.order.id" />
	},
	'ParticipationDetailButton',
)


export const CourseDateDetail = (
	<GenericPage pageName="courseDateDetail">
		<DataBindingProvider stateComponent={FeedbackRenderer}>
			<EntitySubTree entity="CourseDate(id = $id)">
				<TitleBar>
					<Field<string> field="course.locales(locale.code='cs').title"/>: <DateFieldView locale="cs-CZ" field="start" />
				</TitleBar>
				<EditButton pageName="createParticipation">Přidat účastníka</EditButton>
				<DataGrid entities="Participation[date.id = $id]" itemsPerPage={50}>
					<DateCell
						field="createdAt"
						header="Vytvořeno"
						initialOrder="asc"
						locale="cs-CZ"
						format={{dateStyle: "medium", timeStyle: "short"} as any}
						shrunk
						hidden
					/>
					<TextCell field="email" header="Email" />
					<TextCell field="phoneNumber" header="Telefon" />
					<TextCell field="peopleCount" header="Počet lidí" />
					<DateCell
						field="canceledAt"
						header="Zrušeno"
						locale="cs-CZ"
						format={{dateStyle: "medium", timeStyle: "short"} as any}
						fallback="Ne"
					/>
					<TextCell field="orderItem.order.fakturoidId" header="Faktura" format={id => <LinkToFakturoidInvoice invoiceId={id as string}>{id as string}</LinkToFakturoidInvoice>} />
					<TextCell field="internalNote" header="Interní poznámka" />
					<TextCell field="note" header="Poznámka" hidden />
					<TextCell field="kidsUnder4" header="Děti <4 roky" hidden />
					<TextCell field="kidsUnder12" header="Děti <12 roky" hidden />
					<TextCell field="kidsUnder18" header="Děti <18 roky" hidden />
					<TextCell field="name" header="Jméno" hidden />
					<TextCell field="alergies" header="Alergie" hidden />
					<BooleanCell field="overNight" header="Přes noc" booleanStyle="checkCross" hidden />
					<TextCell field="secretCode" header="Tajný kód" hidden />
					<TextCell field="orderItem.order.email" header="Email objednavatele" hidden />
					<TextCell field="orderItem.order.phoneNumber" header="Telefon objednavatele" hidden />
					<GenericCell header="Pozice" hidden>
						<HasMany field="participants">
							<Field field="occupation" />
							<br />
						</HasMany>
					</GenericCell>
					<DateCell
						field="reminderSent"
						header="Odeslána připomínka"
						locale="cs-CZ"
						format={{dateStyle: "medium", timeStyle: "short"} as any}
						hidden
					/>
					<GenericCell shrunk canBeHidden={false}>
						<ParticipationDetailButton />
					</GenericCell>
				</DataGrid>
			</EntitySubTree>
		</DataBindingProvider>
	</GenericPage>
)


const Participation = Component<{ hideSelect?: boolean }>(
	({ hideSelect }) => <>
		<Field field="canceledAt" format={c => c ? <p style={{fontSize: '1.2em', 'fontWeight': 'bold'}}>❌ Účast zrušena</p> : <></>} />
		<Conditional showIf={it => it.getField('secretCode').value !== null}>
			Na webu: <Field field="secretCode" format={secretCode => <LinkToWeb path={`cs/participation/${secretCode}`}>zrušení účasti</LinkToWeb>} />
		</Conditional>

		{!hideSelect && <SelectField
			field="date"
			label="Kurz"
			searchByFields={["course.locales(locale.code='cs').title", "start"]}
			options={{ entities: { entityName: "CourseDate" } }}
			renderOption={date => <Entity accessor={date}>
				<Field field="course.locales(locale.code='cs').title" /> dne <DateFieldView field="start" locale="cs-CZ" />
			</Entity>}
			optionsStaticRender={<>
				<Field field="course.locales(locale.code='cs').title" /> dne <DateFieldView field="start" locale="cs-CZ" />
			</>}
		/>}
		<NumberField field="peopleCount" label="Počet osob" defaultValue={1} />
		<TextField field="name" label="Jméno" />
		<TextField field="email" label="Email" />
		<TextField field="phoneNumber" label="Telefon" />
		<TextField field="note" label="Poznámka" />
		<CheckboxField field="overNight" label="Přes noc" />
		<TextAreaField field="alergies" label="Alergie" />
		<TextAreaField field="internalNote" label="Interní poznámka" />
		<NumberField field="kidsUnder4" label="Děti <4 roky" />
		<NumberField field="kidsUnder12" label="Děti <12 let" />
		<NumberField field="kidsUnder18" label="Děti <18 let" />
		<Repeater label={undefined} field="participants" orderBy={undefined}>
			<TextField field="occupation" label="Pozice" />
		</Repeater>
	</>,
	'Participation',
)


export const ParticipationDetail = (
	<EditPage pageName="participationDetail" entity="Participation(id = $id)">
		{/* <TitleBar>Objednávka ze <DateFieldView field="createdAt" locale="cs-CZ" /></TitleBar> */}
		<Participation />

	</EditPage>
)

export const ParticipationCreate = (
	<CreatePage pageName="createParticipation" entity="Participation" setOnCreate="(date.id = $id)" rendererProps={{ title: "Přidat účastníka" }} redirectOnSuccess={(st) => ({...st, pageName: 'courseDateDetail'})}>
		<Participation hideSelect={true} />
	</CreatePage>
)

export const CreateVoucherPage = <CreatePage pageName="createVoucherCode" entity="VoucherCode" rendererProps={{ title: "Nový kód" }} redirectOnSuccess={(st) => ({...st, pageName: 'voucherCodes'})}>
	<TextField field="code" label="Kód" />
	<SelectField
		field="voucher"
		label="Voucher"
		searchByFields={["discount"]}
		options={{ entities: { entityName: "Voucher" } }}
		renderOption={date => <Entity accessor={date}>
			<Field<number> field="discount" format={p => p && p / 100} /> Kč, do <DateFieldView field="expiresAt" locale="cs-CZ" /> v sezóně <Field field="season.name" />
		</Entity>}
		optionsStaticRender={<>
			<Field<number> field="discount" format={p => p && p / 100} /> Kč, do <DateFieldView field="expiresAt" locale="cs-CZ" /> v sezóně <Field field="season.name" />
		</>}
	/>
</CreatePage>

const VoucherCode = Component(
	() => <>
		Voucher s kódem <Field field="code" /><br />
		Jméno na voucher: <Field field="holderName" />
	</>,
	'VoucherCode',
)

const OrderItem = Component(
	() => {
		const participation = useEntity('participation')
		const voucherCode = useEntity('voucherCode')
		const shipment = useEntity('shipment')

		if (participation.existsOnServer) {
			return (
				<HasOne field="participation">
					<Participation />
				</HasOne>
			)
		} else if (voucherCode.existsOnServer) {
			return (
				<HasOne field="voucherCode">
					<VoucherCode />
				</HasOne>
			)
		} else if (shipment.existsOnServer) {
			return (
				<p>
					<b>Doprava</b><br />
					<Field field="shipment.address.name" /><br />
					<Field field="shipment.address.firstLine" /><br />
					<Field field="shipment.address.secondLine" /><br />
					<Field field="shipment.address.postCode" />
				</p>
			)
		} else {
			return <>Neznámá položka</>
		}
	},
	() => <>
		<HasOne field="participation">
			<Participation />
		</HasOne>
		<HasOne field="voucherCode">
			<VoucherCode />
		</HasOne>
		<Field field="shipment.address.name" />
		<Field field="shipment.address.firstLine" />
		<Field field="shipment.address.secondLine" />
		<Field field="shipment.address.postCode" />
	</>,
	'OrderItem',
)

export const Order = (
	<EditPage pageName="orderDetail" entity="Order(id = $id)">
		<TitleBar>Objednávka ze <DateFieldView field="createdAt" locale="cs-CZ" format={{dateStyle: "medium", timeStyle: "short"} as any} /></TitleBar>
		Email: <Field field="email" /><br />
		Telefon: <Field field="phoneNumber" /><br />
		Cena: <Field<number> field="totalPrice" format={p => p && p / 100} /> Kč<br />
		Na webu: <Field field="secretCode" format={secretCode => <LinkToWeb path={`cs/summary/${secretCode}`}>souhrn objednávky</LinkToWeb>} /><br />
		<Field<string> field="fakturoidId" format={id => id && <LinkToFakturoidInvoice invoiceId={id as string}>Faktura ve fakturoidu</LinkToFakturoidInvoice>}/>

		<Repeater enableAddingNew={false} enableRemoving={false} orderBy={undefined} field="items" label="Položky" initialEntityCount={0}>
			<OrderItem />
		</Repeater>

		<HasOne field="usedVoucher">
			<Conditional showIf={it => it.existsOnServer}>
				<Box heading="Použitý voucher">
					<Field field="code" /> za <Field<number> field="voucher.discount" format={p => p && p / 100} /> Kč
				</Box>
			</Conditional>
		</HasOne>

		<HasOne field="stripeSession">
			<Box heading="Platba">
				Checkout ID: <Field field="stripeId" /><br />
				Stav: <Field field="state" format={(v) => v === "created" ? "Čeká na platbu" : (v === "successful" ? "Dokončeno" : v)} />
			</Box>
		</HasOne>
	</EditPage>
)

