import {
	AltPersistButton,
	Box,
	EditPage,
	HasOne,
	Repeater,
	LegacyDeprecatedEditorFormerlyKnownAsRichTextField,
	TextField,
	VariableScalar,
} from '@contember/admin'
import * as React from 'react'
import { LocaleSideDimension } from '../components'
import { fullEditorInlineButtons } from '../utils'

export const FaqPage = (
	<EditPage
		pageName="faqPage"
		entity="FaqPage(unique = One)"
		rendererProps={{ title: 'FAQ', persistButtonComponent: AltPersistButton }}
	>
		<LocaleSideDimension>
			<TextField field="link.url" label="URL" defaultValue={new VariableScalar('slugPrefix')} />
		</LocaleSideDimension>

		<Box heading="FAQ">
			<LocaleSideDimension>
				<TextField field="title" label="Main Title" />
				<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="text" label="Description Text" />
			</LocaleSideDimension>
		</Box>

		<Box heading="Faq Section">
			<HasOne field="faqSection">
				<Repeater field="items" label="Faq option" sortableBy="order">
					<LocaleSideDimension>
						<TextField field="title" label="Title" />
						<LegacyDeprecatedEditorFormerlyKnownAsRichTextField inlineButtons={fullEditorInlineButtons} field="text" label="Text" />
					</LocaleSideDimension>
				</Repeater>
			</HasOne>
		</Box>
	</EditPage>
)
