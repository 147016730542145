export * from '../../../plugins/tenant/admin/pages'
export * from '../../../plugins/translations/admin/pages'
export * from './checkout'
export * from './Dashboardpage'
export * from './HomePage'
export * from './LocalesPage'
export * from './RedirectsPage'
export * from './FooterMenu'
export * from './HeaderMenu'
export * from './CoursesPage'
export * from './ContactPage'
export * from './PrivatePage'
export * from './FaqPage'
export * from './VoucherPage'
export * from './TermsAndConditionsPage'
