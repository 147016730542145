import { AltPersistButton, Box, EditPage, Repeater, TextField } from '@contember/admin'
import * as React from 'react'
import { LocaleSideDimension } from '../components'
import { ImageField, SocialLinksField } from '../fields'

export const HeaderMenu = (
	<EditPage
		pageName="headerMenu"
		entity="HeaderMenu(unique = One)"
		rendererProps={{ title: 'Header Menu', persistButtonComponent: AltPersistButton }}
	>
		<ImageField field="logo" label="Logo" />
		<TextField field="text" label="text" />
		<Box heading="Social Links">
			<SocialLinksField field="socialLinks" label="Inputs" />
		</Box>

		<Box heading="Links">
			<Repeater field="links" label="Links" sortableBy="order">
				<LocaleSideDimension>
					<TextField field="text" label="Text" />
					<TextField field="url" label="URL" />
				</LocaleSideDimension>
			</Repeater>
		</Box>
	</EditPage>
)
