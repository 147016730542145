import { AltPersistButton, MultiEditPage, TextField } from '@contember/admin'
import * as React from 'react'

export const LocalesPage = (
	<MultiEditPage
		entities="Locale"
		pageName="locales"
		rendererProps={{ title: 'Locales', persistButtonComponent: AltPersistButton }}
	>
		<TextField label="Code" field="code" />
		<TextField label="Label" field="label" />
	</MultiEditPage>
)
