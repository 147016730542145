import {
	Block,
	Box,
	Component,
	DiscriminatedBlocks,
	FormGroupProps,
	HasOne,
	SelectField,
	TextField,
} from '@contember/admin'
import * as React from 'react'

export interface LinkFieldProps {
	field: string
	label: FormGroupProps['label']
}

export const LinkField = Component<LinkFieldProps>(props => (
	<Box heading={props.label}>
		<HasOne field={props.field}>
			<DiscriminatedBlocks field="type" label="Type">
				<Block discriminateBy="internal" label="Internal">
					<TextField field="title" label="Title" />
					<SelectField field="internalLink" label="URL" options="Linkable.url" />
				</Block>
				<Block discriminateBy="external" label="External">
					<TextField field="title" label="Title" />
					<TextField field="externalLink" label="URL" />
				</Block>
			</DiscriminatedBlocks>
		</HasOne>
	</Box>
))
