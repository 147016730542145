import {
	AltPersistButton,
	Box,
	EditPage,
	HasOne,
	Repeater,
	LegacyDeprecatedEditorFormerlyKnownAsRichTextField,
	TextField,
	VariableScalar,
	VideoUploadField,
} from '@contember/admin'
import { MediumField } from '../fields'
import * as React from 'react'
import { LocaleSideDimension } from '../components'
import { ImageField, ImageListField } from '../fields'

export const HomePage = (
	<EditPage
		pageName="homePage"
		entity="HomePage(unique = One)"
		rendererProps={{ title: 'Home', persistButtonComponent: AltPersistButton }}
	>
		<LocaleSideDimension>
			<TextField field="link.url" label="URL" defaultValue={new VariableScalar('slugPrefix')} />
		</LocaleSideDimension>

		<Box heading="Cover">
			<MediumField field="medium" label="Medium" />
			<LocaleSideDimension>
				<TextField field="title" label="Main Title" />
			</LocaleSideDimension>
		</Box>

		<Box heading="Intro Section">
			<HasOne field="introSection">
				<LocaleSideDimension>
					<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="text" label="Text" />
				</LocaleSideDimension>
				<ImageListField field="images" label="Images" />
			</HasOne>
		</Box>

		<Box heading="About Courses">
			<HasOne field="aboutCourse">
				<TextField field="textPicture" label="Text F." />
				<LocaleSideDimension>
					<TextField field="title" label="Title" />
					<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="text" label="Text" />
					<TextField field="moreAboutButton" label="Button more about" />
				</LocaleSideDimension>
			</HasOne>
		</Box>
		<Box heading="Video Section">
			<HasOne field="videos">
				<Repeater field="items" label="Items" sortableBy="order">
					<VideoUploadField field="video.url" label="Video" />
					<ImageField field="video.poster" label="Poster Image" />
				</Repeater>
			</HasOne>
		</Box>
	</EditPage>
)
