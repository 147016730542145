import { Pages } from '@contember/admin'
import * as React from 'react'
import { FuegoLayout } from './Layout'
import * as pageList from './pages'
import { SideMenu } from './SideMenu'
import './_theme.sass'

function Layout({ children }: { children?: React.ReactNode }) {
	return <FuegoLayout SideMenu={SideMenu} children={children} />
}

export default () => <Pages layout={Layout} children={Object.values(pageList)} />
