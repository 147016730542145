import { AltPersistButton, EditPage, TextField } from '@contember/admin'
import * as React from 'react'
import { LocaleSideDimension } from '../components'
import { ImageField } from '../fields'

export const FooterMenu = (
	<EditPage
		pageName="footerMenu"
		entity="FooterMenu(unique = One)"
		rendererProps={{ title: 'Footer Menu', persistButtonComponent: AltPersistButton }}
	>
		<ImageField field="logo" label="Logo" />

		<LocaleSideDimension>
			<TextField field="fuego" label="Fuego" />
			<TextField field="termsAndCondition" label="Terms and Condition" />
			<TextField field="url" label="Terms and Condition Url" />
		</LocaleSideDimension>
	</EditPage>
)
