import {
	Box,
	Component,
	CreatePage,
	EditPage,
	Field,
	NavigateBackButton,
	PageLinkButton,
	SelectField,
	TableCell,
	TablePage,
	TextField,
} from '@contember/admin'
import * as React from 'react'
import { TranslationEditButton } from '../components/EditButton'

const TranslationCatalogueForm = Component(
	() => (
		<Box>
			<SelectField field="domain" label="Domain" options="TranslationDomain.name" />
			<TextField field="identifier" label="Identifier" allowNewlines={false} />
			<TextField field="name" label="Name" allowNewlines={false} />
			<SelectField field="fallback" label="Fallback catalogue" options="TranslationCatalogue.name" />
		</Box>
	),
	'TranslationCatalogueForm',
)

export const TranslationCatalogueListPage = (
	<TablePage
		pageName="translationCatalogueList"
		entities="TranslationCatalogue"
		orderBy="domain.name asc, name asc"
		rendererProps={{
			title: 'Translation Catalogues',
			actions: <PageLinkButton to="translationCatalogueCreate">Add a new translation catalogue</PageLinkButton>,
		}}
	>
		<TableCell>
			<Field field="domain.name" />
		</TableCell>

		<TableCell>
			<Field field="name" />
		</TableCell>

		<TableCell shrunk>
			<TranslationEditButton pageName="translationCatalogueEdit" />
		</TableCell>
	</TablePage>
)

export const TranslationCatalogueCreatePage = (
	<CreatePage
		pageName="translationCatalogueCreate"
		entity="TranslationCatalogue"
		rendererProps={{
			title: 'Add a new translation catalogue',
			navigation: <NavigateBackButton to="translationCatalogueList">Translation catalogues</NavigateBackButton>,
		}}
		redirectOnSuccess={request => ({ ...request, pageName: 'translationCatalogueList' })}
	>
		<TranslationCatalogueForm />
	</CreatePage>
)

export const TranslationCatalogueEditPage = (
	<EditPage
		pageName="translationCatalogueEdit"
		entity="TranslationCatalogue(id = $id)"
		rendererProps={{
			title: 'Edit translation catalogue',
			navigation: <NavigateBackButton to="translationCatalogueList">TranslationCatalogues</NavigateBackButton>,
		}}
	>
		<TranslationCatalogueForm />
	</EditPage>
)
