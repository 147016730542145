import { Component, FormGroupProps, HasOne, Repeater } from '@contember/admin'
import * as React from 'react'
import { ImageField } from './ImageField'

export interface ImageListFieldProps {
	field: string
	label?: FormGroupProps['label']
}

export const ImageListField = Component<ImageListFieldProps>(props => (
	<HasOne field={props.field}>
		<Repeater field="items" sortableBy="order" label={props.label}>
			<ImageField field="image" />
		</Repeater>
	</HasOne>
))
