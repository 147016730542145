import { Menu } from '@contember/admin'
import * as React from 'react'
import { TenantMenuItem } from '../../plugins/tenant/admin/MenuItem'
import { TranslationMenuItem } from '../../plugins/translations/admin/components'

export const SideMenu = React.memo(props => {
	return (
		<Menu>
			<Menu.Item title="Rezervace">
				<Menu.Item title="Seznam kurzů" to="courses" />
				<Menu.Item title="Termíny" to="courseDates" />
				<Menu.Item title="Objednávky" to="orderList" />
				<Menu.Item title="Nedokončené objednávky" to="orderNotprocessedList" />
				<Menu.Item title="K odeslání" to="shipments" />
				<Menu.Item title="Vouchery" to="vouchers" />
				<Menu.Item title="Vydané vouchery" to="voucherCodes" />
				<Menu.Item title="Sezóny" to="voucherSeasons" />
			</Menu.Item>
			<Menu.Item title="Pages">
				<Menu.Item title="Header" to="headerMenu" />
				<Menu.Item title="Home" to="homePage" />
				<Menu.Item title="Redirect" to="redirects" />
				<Menu.Item title="Locales" to="locales" />
				<Menu.Item title="Courses Page" to="coursesPage" />
				<Menu.Item title="FAQ" to="faqPage" />
				<Menu.Item title="Contact Page" to="contactPage" />
				<Menu.Item title="Private Page" to="privatePage" />
				<Menu.Item title="E-shop" to="voucherPage" />
				<Menu.Item title="Terms And Conditions" to="termsAndConditionsPage" />
				<Menu.Item title="Footer" to="footerMenu" />
			</Menu.Item>
			<Menu.Item title="Advanced">
				<TenantMenuItem />
			</Menu.Item>
			<TranslationMenuItem />
		</Menu>
	)
})
