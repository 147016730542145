import {
	Block,
	Box,
	Component,
	DiscriminatedBlocks,
	FormGroupProps,
	HasOne,
	Repeater,
	SelectField,
	TextField,
	VideoUploadField,
} from '@contember/admin'
import * as React from 'react'
import { ImageField } from './ImageField'

export interface MediumFieldProps {
	field: string
	label?: FormGroupProps['label']
}

export const MediumField = Component<MediumFieldProps>(props => (
	<Box heading={props.label}>
		<HasOne field={props.field}>
			<DiscriminatedBlocks field="type" label="Type">
				<Block discriminateBy="Image" label="Image">
					<ImageField field="image" label="Image" />
				</Block>
				<Block discriminateBy="Video" label="Video">
					<VideoUploadField field="video.url" label="Video" />
					<ImageField field="video.poster" label="Poster Image" />{' '}
				</Block>
			</DiscriminatedBlocks>
		</HasOne>
	</Box>
))
