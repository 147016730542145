import { Component, Environment, FormGroupProps, SlugField, SlugFieldProps } from '@contember/admin'
import * as React from 'react'

export interface LinkUrlFieldProps extends Partial<SlugFieldProps> {
	derivedFrom: SlugFieldProps['derivedFrom']
	label: FormGroupProps['label']
}

export const LinkUrlField = Component<LinkUrlFieldProps>(
	({ derivedFrom, label, ...props }) => (
		<SlugField
			field="link.url"
			derivedFrom={derivedFrom}
			label={label}
			unpersistedHardPrefix=""
			persistedHardPrefix="/"
			persistedSoftPrefix={(environment: Environment) => {
				const locale = environment.getValueOrElse<string>('currentLocaleCode', 'cs')
				const langPrefix = locale === 'cs' ? '' : `${locale}/`

				return `${langPrefix}`
			}}
			{...props}
		/>
	),
	'LinkUrlField',
)
