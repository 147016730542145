import {
	AltPersistButton,
	Box,
	EditPage,
	HasOne,
	Literal,
	Repeater,
	LegacyDeprecatedEditorFormerlyKnownAsRichTextField,
	SelectField,
	tenantErrorMessages,
	TextField,
	VariableScalar,
} from '@contember/admin'
import * as React from 'react'
import { LocaleSideDimension } from '../components'
import { ImageField, MediumField, SocialLinksField } from '../fields'

export const ContactPage = (
	<EditPage
		pageName="contactPage"
		entity="ContactPage(unique = One)"
		rendererProps={{ title: 'Contact Page', persistButtonComponent: AltPersistButton }}
	>
		<LocaleSideDimension>
			<TextField field="link.url" label="URL" defaultValue={new VariableScalar('slugPrefix')} />
		</LocaleSideDimension>

		<Box heading="Contact">
			<MediumField field="medium" label="Medium" />
			<LocaleSideDimension>
				<TextField field="title" label="Main Title" />
				<TextField field="email" label="Email" />
				<TextField field="phone" label="Phone" />
			</LocaleSideDimension>
		</Box>

		<Box heading="Member Section">
			<HasOne field="memberContact">
				<LocaleSideDimension>
					<TextField field="title" label="Title" />
				</LocaleSideDimension>
				<Repeater field="singles" label="Member" sortableBy="order">
					<ImageField field="image" label="Photo" />
					<LocaleSideDimension>
						<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="name" label="Name" />
						<Box heading="Social Links">
							<SocialLinksField field="socialLinks" label="Items" />
						</Box>
						<TextField field="position" label="Position" />
						<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="text" label="Text" />
					</LocaleSideDimension>
				</Repeater>
			</HasOne>
		</Box>

		<Box heading="Sponsors">
			<HasOne field="imageListUrl">
				<LocaleSideDimension>
					<TextField field="title" label="Title" />
				</LocaleSideDimension>
				<Repeater field="items" label="Sponsor" sortableBy="order">
					<ImageField field="image" label="Logo" />
					<TextField field="url" label="Url" />
				</Repeater>
			</HasOne>
		</Box>

		<Box heading="Contact Form">
			<HasOne field="contactForm">
				<LocaleSideDimension>
					<TextField field="title" label="Title" />
					<Box heading="Input">
						<TextField field="name" label="Name" />
						<TextField field="email" label="Email" />
						<TextField field="phone" label="Phone" />
						<TextField field="text" label="Text" />
						<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="descText" label=" Description Text" />
						<TextField field="button" label="Send Button" />
					</Box>
				</LocaleSideDimension>
			</HasOne>
		</Box>
	</EditPage>
)
