import {
  AltPersistButton,
  EditPage,
  LegacyDeprecatedEditorFormerlyKnownAsRichTextField,
  TextField,
  VariableScalar,
} from "@contember/admin";
import * as React from "react";
import { LocaleSideDimension } from "../components";

export const TermsAndConditionsPage = (
  <EditPage
    pageName="termsAndConditionsPage"
    entity="TermsAndConditionsPage(unique = One)"
    rendererProps={{
      title: "Terms and conditions",
      persistButtonComponent: AltPersistButton,
    }}
  >
    <LocaleSideDimension>
      <TextField
        field="link.url"
        label="URL"
        defaultValue={new VariableScalar("slugPrefix")}
      />
      <TextField field="title" label="Main Title" />
      <TextField field="subTitle" label="Subtitle" />
      <LegacyDeprecatedEditorFormerlyKnownAsRichTextField
        field="text"
        label="Description Text"
      />
    </LocaleSideDimension>
  </EditPage>
);
