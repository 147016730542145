import {
	AltPersistButton,
	Box,
	EditPage,
	HasOne,
	Repeater,
	LegacyDeprecatedEditorFormerlyKnownAsRichTextField,
	tenantErrorMessages,
	TextField,
	VariableScalar,
	EntityListSubTree,
	Field,
} from '@contember/admin'
import * as React from 'react'
import { LocaleSideDimension } from '../components'
import { ImageField } from '../fields'

export const VoucherPage = (
	<EditPage
		pageName="voucherPage"
		entity="VoucherPage(unique = One)"
		rendererProps={{ title: 'E-Shop', persistButtonComponent: AltPersistButton }}
	>
		<LocaleSideDimension>
			<TextField field="link.url" label="URL" defaultValue={new VariableScalar('slugPrefix')} />
		</LocaleSideDimension>

		<Box heading="Vouchers">
			<ImageField field="image" label="Cover Image" />
			<LocaleSideDimension>
				<TextField field="title" label="Main Title" />
				<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="text" label="Description Text" />
			</LocaleSideDimension>
		</Box>

		<Box heading="Vouchers checkout">
			<HasOne field="voucherSpecific">
				<ImageField field="image" label="Voucher Image" />
				<LocaleSideDimension>
					<TextField field="title" label="Title" />
				</LocaleSideDimension>
				
				<EntityListSubTree entities="Voucher[available=true]">
					<h3><Field<number> field="discount" format={p => p && p / 100} /> Kč</h3>
					<LocaleSideDimension>
						<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="description" label="Description" />
					</LocaleSideDimension>
				</EntityListSubTree>
			</HasOne>
		</Box>
	</EditPage>
)
