import {
	AltPersistButton,
	Box,
	EditPage,
	HasOne,
	Repeater,
	LegacyDeprecatedEditorFormerlyKnownAsRichTextField,
	tenantErrorMessages,
	TextField,
	VariableScalar,
} from '@contember/admin'
import { MediumField } from '../fields'
import * as React from 'react'
import { LocaleSideDimension } from '../components'
import { fullEditorInlineButtons } from '../utils/fullEditorInlineButtons'
import { ImageField } from '../fields'

export const CoursesPage = (
	<EditPage
		pageName="coursesPage"
		entity="CoursesPage(unique = One)"
		rendererProps={{ title: 'Courses Page', persistButtonComponent: AltPersistButton }}
	>
		<LocaleSideDimension>
			<TextField field="link.url" label="URL" defaultValue={new VariableScalar('slugPrefix')} />
		</LocaleSideDimension>

		<Box heading="Courses">
			<MediumField field="medium" label="Medium" />
			<LocaleSideDimension>
				<TextField field="title" label="Main Title" />
				<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="text" label="Description Text" />
			</LocaleSideDimension>
		</Box>

		<Box heading="Courses FAQ">
			<HasOne field="coursesFaq">
				<LocaleSideDimension>
					<TextField field="title" label="Title" />
					<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="descText" label="descText" />
					<TextField field="button" label="button" />
				</LocaleSideDimension>
			</HasOne>
		</Box>

		{/* <Box heading="Courses">
			<HasOne field="course">
				<LocaleSideDimension>
					<TextField field="title" label="Title" />
				</LocaleSideDimension>
				<Repeater field="items" label="Course" sortableBy="order">
					<ImageField field="image" label="Main Image" />
					<LocaleSideDimension>
						<TextField field="courseTitle" label="Title of the course" />
						<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="courseDescription" label="Description of the course" />
					</LocaleSideDimension>

					<Box heading="Menu">
						<LocaleSideDimension>
							<TextField field="menuTitle" label="Title" />
							<TextField field="menuPrice" label="Price" />
							<TextField field="menuBtn" label="Button" />
							<LegacyDeprecatedEditorFormerlyKnownAsRichTextField inlineButtons={fullEditorInlineButtons} field="menuText" label="menu text" />
						</LocaleSideDimension>
					</Box>
				</Repeater>
			</HasOne>
		</Box> */}

		<Box heading="Special Offer ">
			<HasOne field="specialOfferList">
				<Repeater field="items" label="Items" sortableBy="order">
					<LocaleSideDimension>
						<TextField field="title" label="Title" />
						<LegacyDeprecatedEditorFormerlyKnownAsRichTextField field="text" label="Text" />
						<TextField field="contactButton" label="Button" />
						<TextField field="url" label="url" />
					</LocaleSideDimension>
				</Repeater>
			</HasOne>
		</Box>
	</EditPage>
)
