import { GenericPage, Table, TableCell, TableRow, TitleBar, useSessionToken } from '@contember/admin'
import * as React from 'react'

const Translate = React.memo(() => {
	const token = useSessionToken()

	return <a href={`http://localhost:8001/_translate?token=${token}`}>Překládat</a>
})

export const DashboardPage = (
	<GenericPage pageName="dashboard">
		<TitleBar>Fuego admin</TitleBar>
		<div style={{ marginBottom: '1.5rem' }}>
			<Table size="default">
				<TableRow>
					<TableCell>
						<b>Production</b>
					</TableCell>
					<TableCell shrunk>
						<a href="https://www.fuego.mgw.cz/" target="_blank" rel="noopener">
							fuego still just beta
						</a>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<b>Beta</b>
					</TableCell>
					<TableCell shrunk>
						<a href="https://fuego.mgw.cz/" target="_blank" rel="noopener">
							fuego.mgw.cz
						</a>
					</TableCell>
				</TableRow>
			</Table>
		</div>

		<Translate />
	</GenericPage>
)
