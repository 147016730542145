import { Component, Field, FormGroup, TextFieldProps, TextInput, useField } from '@contember/admin'
import { ChangeEvent, default as React, KeyboardEvent, useCallback, useState } from 'react'

export const FastTextField = Component<TextFieldProps>(
	props => {
		const field = useField<string>(props)
		const [value, setValue] = useState<string | null>(field.value)

		const shouldAllowNewLines = useCallback(
			(value: string | null) => props.allowNewlines && value !== null && value.includes('\n'),
			[props.allowNewlines],
		)

		const setValueWrapper = useCallback(
			(newValue: string) => {
				setValue(newValue)

				if (shouldAllowNewLines(value) !== shouldAllowNewLines(newValue)) {
					field.updateValue(newValue)
				}
			},
			[shouldAllowNewLines, value, field],
		)

		const onFocus = useCallback(() => setValue(field.value), [field.value, setValue])
		const onBlur = useCallback(() => value !== field.value && field.updateValue(value ?? ''), [field, value])

		const onChange = useCallback(
			(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => setValueWrapper(e.target.value),
			[setValueWrapper],
		)

		// convert input to textarea and back
		// known issue: this will currently loose focus
		const onKeyDown = useCallback(
			(e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
				if (props.allowNewlines && e.key === 'Enter' && value !== null && !value.includes('\n')) {
					e.preventDefault()
					const el = e.target as HTMLTextAreaElement | HTMLInputElement
					const { selectionStart, selectionEnd } = el

					const before = value.substring(0, selectionStart ?? value.length)
					const after = value.substring(selectionEnd ?? value.length)
					const newValue = before + '\n' + after
					setValueWrapper(newValue)
				}
			},
			[props.allowNewlines, setValueWrapper, value],
		)

		return (
			<FormGroup label={props.label}>
				<TextInput
					value={value ?? ''}
					onChange={onChange}
					onFocus={onFocus}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					validationState={field.errors !== undefined ? 'invalid' : undefined}
					readOnly={props.readOnly}
					allowNewlines={shouldAllowNewLines(value) as any}
					placeholder={props.placeholder}
				/>
			</FormGroup>
		)
	},
	props => <Field defaultValue={props.defaultValue} field={props.field} isNonbearing={props.isNonbearing} />,
	'FastTextField',
)
