import {
	DimensionsSwitcher,
	Layout,
	LayoutHeading,
	LogoutLink,
	PageLink,
	SeamlessDropdown,
	UserMiniControl,
} from '@contember/admin'
import * as React from 'react'

export interface FuegoLayoutProps {
	children?: React.ReactNode
	SideMenu: React.ComponentType
	Heading?: React.ComponentType
	LocaleSwitcher?: React.ComponentType
	Userbox?: React.ComponentType
}

function FuegoHeading() {
	return (
		<>
			<PageLink to="dashboard">
				<LayoutHeading label={<>Fuego</>} />
			</PageLink>
			<div style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '40px' }}>
				<span style={{ marginRight: '10px' }}>Locale:</span>
				<DimensionsSwitcher
					optionEntities="Locale"
					orderBy="code asc"
					dimension="locale"
					labelField="code"
					slugField="code"
					maxItems={3}
				/>
			</div>
		</>
	)
}

function FuegoLogoutButton(props: { onClick: () => void }) {
	return (
		<span role="button" {...props}>
			Sign Out
		</span>
	)
}

function FuegoUserbox() {
	return (
		<SeamlessDropdown caret inline label={<UserMiniControl name="Fuego" note="Admin" />}>
			<LogoutLink Component={FuegoLogoutButton} />
		</SeamlessDropdown>
	)
}

function FuegoSidebar(props: { SideMenu: React.ComponentType }) {
	const { SideMenu } = props

	return (
		<div className="cui-layout-sideBar-wrap">
			<div className="cui-layout-sideBar-wrap-in">
				<SideMenu />
			</div>
		</div>
	)
}

export function FuegoLayout({ children, SideMenu, Userbox, Heading }: FuegoLayoutProps) {
	Heading = Heading || FuegoHeading
	Userbox = Userbox || FuegoUserbox

	return (
		<div className="fuego">
			<Layout
				topStart={<Heading />}
				topEnd={<Userbox />}
				sideBar={<FuegoSidebar SideMenu={SideMenu} />}
				mainStart={children}
			/>
		</div>
	)
}
