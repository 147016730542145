import {
	FieldAccessor,
	SimpleRelativeSingleField,
	SimpleRelativeSingleFieldProps,
	SingleLineTextInputProps,
	TextInput,
} from '@contember/admin'
import React from 'react'

export type PriceFieldProps = SimpleRelativeSingleFieldProps &
	Omit<SingleLineTextInputProps, 'value' | 'onChange' | 'validationState' | 'allowNewlines'>

export const PriceField = SimpleRelativeSingleField<PriceFieldProps, number>(
	(fieldMetadata, { defaultValue, ...props }) => {
		const generateOnChange = (data: FieldAccessor<number>) => (e: React.ChangeEvent<HTMLInputElement>) => {
			data.updateValue(Math.round(e.target.valueAsNumber * 100))
		}

		return (
			<TextInput
				value={
					typeof fieldMetadata.field.value === 'number'
						? (fieldMetadata.field.value / 100).toString(10)
						: '0'
				}
				onChange={generateOnChange(fieldMetadata.field)}
				validationState={fieldMetadata.field?.errors !== undefined ? 'invalid' : undefined}
				type="number"
				min="0"
				step="0.01"
				{...props}
			/>
		)
	},
	'PriceField',
)
