import { Component, FormGroupProps, HasOne, ImageUploadField } from '@contember/admin'
import * as React from 'react'

export interface ImageFieldProps {
	field: string
	label?: FormGroupProps['label']
	description?: FormGroupProps['description']
	labelDescription?: FormGroupProps['labelDescription']
}

export const ImageField = Component<ImageFieldProps>((props: ImageFieldProps) => {
	return (
		<HasOne field={props.field}>
			<ImageUploadField
				label={props.label}
				field="url"
				description={props.description}
				labelDescription={props.labelDescription}
				imageWidthField="width"
				imageHeightField="height"
				fileSizeField="size"
				fileTypeField="type"
			/>
		</HasOne>
	)
})
