import {
	ChangePassword,
	EditUserInProject,
	GenericPage,
	InviteUserToProject,
	LayoutInner,
	Page,
	RolesConfig,
	UsersManagement,
} from '@contember/admin'
import * as React from 'react'

const rolesConfig: RolesConfig = {
	// @TODO: add option to list more roles
	admin: {
		name: 'Administrator',
		variables: {},
	},
}

export const ChangePasswordPage = (
	<GenericPage pageName="tenantChangePassword">
		<ChangePassword />
	</GenericPage>
)

// query is required, so send a dummy query
export const UsersManagementPage = (
	<GenericPage pageName="tenantUsers">
		<UsersManagement
			rolesDataQuery={`
query {
  _info {
    description
  }
}
				`}
			roleRenderers={{
				admin: () => <>Administrator</>,
			}}
		/>
	</GenericPage>
)
export const InviteUserPage = (
	<GenericPage pageName="tenantInviteUser">
		<InviteUserToProject rolesConfig={rolesConfig} />
	</GenericPage>
)
export const EditUserPage = (
	<Page<{ tenantEditUser: { id: string } }> name="tenantEditUser">
		{({ id }) => (
			<LayoutInner>
				<EditUserInProject rolesConfig={rolesConfig} identityId={id} />
			</LayoutInner>
		)}
	</Page>
)
