import * as React from 'react'
import { Component, Menu } from '@contember/admin'

export const TranslationMenuItem = Component(
	props => (
		<Menu.Item title="Translations">
			<Menu.Item title="Values" to="translationValue" />
			<Menu.Item title="Domains" to="translationDomainList" />
			<Menu.Item title="Catalogues" to="translationCatalogueList" />
		</Menu.Item>
	),
	'TranslationMenuItem',
)
