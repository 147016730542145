import { Component, FormGroupProps, Literal, Repeater, SelectField, TextField } from '@contember/admin'
import * as React from 'react'

export interface SocialLinksFieldProps {
	field: string
	label?: FormGroupProps['label']
}

export const SocialLinksField = Component<SocialLinksFieldProps>((props: SocialLinksFieldProps) => {
	return (
		<Repeater field={props.field} label={props.label} sortableBy="order">
			<SelectField
				field="type"
				label="Type"
				allowNull
				options={[
					{ value: new Literal('instagram'), label: 'Instagram' },
					{ value: new Literal('facebook'), label: 'Facebook' },
					{ value: new Literal('linkedin'), label: 'LinkedIn' },
					{ value: new Literal('youtube'), label: 'YouTube' },
					{ value: new Literal('twitter'), label: 'Twitter' },
				]}
			/>
			<TextField field="url" label="Url" />
		</Repeater>
	)
})
